const MOBILE_THRESHOLD = 900

class GlobalData {
  constructor() {
    this.currentContent = 'react'
    this.currentLanguage = 'en'
    this.toggleMenu = ((window.innerWidth <= MOBILE_THRESHOLD)? false : true)
    // this.toggleMenu = false
  }

  setCurrentContent = (content) => {
    this.currentContent = content
  }

  getCurrentContent = () => {
    return this.currentContent
  }

  setCurrentLanguage = (language) => {
    this.currentLanguage = language
  }

  getCurrentLanguage = () => {
    return this.currentLanguage
  }

  setToggleMenu = (menuState) => {
    this.toggleMenu = menuState
  }

  getToggleMenu = () => {
    return this.toggleMenu
  }
}

export {GlobalData, MOBILE_THRESHOLD}