import React from 'react';
import './css/Content.css';
import ContentItem from "./ContentItem"
import reactLangData from '../data/reactLangData'
import switchLang from '../utils/switchLang';
import {GeneralData} from '../App'

const ContentReact = () => {
  let data = switchLang(GeneralData.getCurrentLanguage(), reactLangData)

  return (
    <div className="Content">
      {Object.keys(data).map(key => (
        <ContentItem key={data[key].title} data={data[key]}/>
      ))}
    </div>
  );
}

export default ContentReact;
