import React from 'react';
import './css/Credit.scss';

import appLangData from '../data/appLangData'
import switchLang from '../utils/switchLang';
import { GeneralData } from '../App'
import version from "../config/portfolioVersion"


const Credit = () => {
  let lang = switchLang(GeneralData.getCurrentLanguage(), appLangData)

  const year = new Date().getFullYear()
  return (
    <div
      className="Credit"
    >{lang.credit.copyright} traiblazingfive {year} v{version}
    </div>
  );
}

export default Credit;
