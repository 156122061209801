const switchLang = (language, dictionary) => {
  if (dictionary.hasOwnProperty(language)) {
    return dictionary[language]
  } else {
    console.log('No translation file - switching to default language')
    // return dictionary['en']
  }
}

// better implementation
// const switchLangRobust = (element, language, dictionary) => {
//   if (dictionary[language].hasOwnProperty(element)) {
//     return dictionary[language][element]
//   } else {
//     console.log('Failed to find translation - EN fallback')
//     return dictionary['en'][element]
//   }
// }

export default switchLang
