import React from 'react';
import './css/Content.css';
import ContentClassic from './ContentClassic';
import ContentReact from './ContentReact';
import ContentOther from './ContentOther';
import ContentBio from './ContentBio';


const Content = (props) => {
  switch (props.content) {
    case 'classic':
      return (<ContentClassic />)
    case 'react':
      return (<ContentReact />)
    case 'other':
      return (<ContentOther />)
    case 'bio':
      return (<ContentBio />)
    default:
      return (<ContentReact />)
  }
}

export default Content;
