import React from 'react';
import './css/MainMenu.scss';

import Switch from './Switch'

import appLangData from '../data/appLangData'
import switchLang from '../utils/switchLang';
import {GeneralData} from '../App'

const MainMenu = (props) => {
  let lang = switchLang(GeneralData.getCurrentLanguage(), appLangData)


  return (
    <div className={(props.toggleMenu)? "MainMenu show" : "MainMenu hide"}>
      {/* MainMenu */}
      <Switch
        cbChangeContent={props.cbChangeContent}
        content="react"
        label={lang.menu.react} />
      <Switch
        cbChangeContent={props.cbChangeContent}
        content="classic"
        label={lang.menu.classic} />
      <Switch
        cbChangeContent={props.cbChangeContent}
        content="other"
        label={lang.menu.other} />
      <Switch
        cbChangeContent={props.cbChangeContent}
        content="bio"
        label={lang.menu.bio} />
    </div>
  );
}

export default MainMenu;