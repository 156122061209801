import tnOrbitalAir from "../assets/thumbnails/jpg/orbital-air.jpg"
import tnObi from "../assets/thumbnails/jpg/obi.jpg"
import tnNightPizza from "../assets/thumbnails/jpg/night-pizza.jpg"
import tnCountdown from "../assets/thumbnails/jpg/countdown.jpg"
import tnHk from "../assets/thumbnails/jpg/hk.jpg"
import tnNicola from "../assets/thumbnails/jpg/nicola.jpg"
import tnPetrochem from "../assets/thumbnails/jpg/petrochem.jpg"
import urlDict from "../config/urlDict"

let creationYears = {
  orbitalAir: 2019,
  obi: 2019,
  nightPizza: 2019,
  countdown: 2019,
  hk: 2019,
  nicola: 2019,
  petrochem: 2019
}

let classicLangData ={
  en: {
    orbitalAir: {
      title: "Orbital Air",
      image: tnOrbitalAir,
      description: "Orbital Air Corporation website; used technologies: HTML5, CSS Grid layout.",
      notes: "fully responsive",
      link: `${urlDict.repo_prefix}/classic/orbital-air/`,
      yearCreated: creationYears.orbitalAir
    },
    obi: {
      title: "Obi - DIY Store",
      image: tnObi,
      description: "Responsive remake of Obi - DIY store. Main design based on floated tiles with the background-image attribute.",
      notes: "fully responsive",
      link: `${urlDict.repo_prefix}/classic/obi/`,
      yearCreated: creationYears.obi
    },
    nightPizza: {
      title: "Night City Pizza",
      image: tnNightPizza,
      description: "Front page of a pizza joint generated based on JSON data using ES5/jQuery javascript. Background generated using the Canvas API. Exploration as to the motivation behind the React library.",
      notes: "fully responsive",
      link: `${urlDict.repo_prefix}/classic/night-pizza/`,
      yearCreated: creationYears.nightPizza
    },
    countdown: {
      title: "Countdown",
      image: tnCountdown,
      description: "Military style countdown using vanilla ES5 javascript including Date object and window.location.href for custom URI countdown generation.",
      notes: "fully responsive",
      link: `${urlDict.repo_prefix}/classic/countdown/`,
      yearCreated: creationYears.countdown
    },
    hk: {
      title: "Heckler & Koch GmbH",
      image: tnHk,
      description: "Weapons manufacturer statical website made using HTML5, CSS3 and @media queries for responsiveness. Javascript ES5 kept to minimum.",
      notes: "fully responsive",
      link: `${urlDict.repo_prefix}/classic/hk/`,
      yearCreated: creationYears.hk
    },
    nicola: {
      title: "NICOLA",
      image: tnNicola,
      description: "Desktop only Nicola soda website with a video element embedded as the main focus of the site.",
      notes: "desktop only",
      link: `${urlDict.repo_prefix}/classic/nicola/`,
      yearCreated: creationYears.nicola
    },
    petrochem: {
      title: "Petrochemical Associates International",
      image: tnPetrochem,
      description: "Static website of a Cyberpunk 2020 universe corporation employing jQuery for the purpose of interactivity.",
      notes: "fully responsive",
      link: `${urlDict.repo_prefix}/classic/petrochem/`,
      yearCreated: creationYears.petrochem
    },
  },
  pl: {
    orbitalAir: {
      title: "Orbital Air",
      image: tnOrbitalAir,
      description: "Strona korporacji Orbital Air; użyte technologie: HTML5, CSS Grid Layout.",
      notes: "w pełni responsywna",
      link: `${urlDict.repo_prefix}/classic/orbital-air/`,
      yearCreated: creationYears.orbitalAir
    },
    obi: {
      title: "Hipermarket Obi",
      image: tnObi,
      description: "Przeróbka strony hipermarketów sieci Obi. Główny motyw bazuje na animowanych blokach z atrybutem tła.",
      notes: "w pełni responsywna",
      link: `${urlDict.repo_prefix}/classic/obi/`,
      yearCreated: creationYears.obi
    },
    nightPizza: {
      title: "Night City Pizza",
      image: tnNightPizza,
      description: "Strona główna sieci pizzeri wygenerowana o dane z JSONa używając jQuery/javascrit w versji ES5. Tło wygenerowane z użyciem API Canvas. Strona służyła zrozumieniu motywów za postaniem biblioteki React.",
      notes: "w pełni responsywna",
      link: `${urlDict.repo_prefix}/classic/night-pizza/`,
      yearCreated: creationYears.nightPizza
    },
    countdown: {
      title: "Countdown",
      image: tnCountdown,
      description: "Zegar odliczający czas do określonego momentu w strefie czasowej ZULU, zbudowany w oparciu o javascript ES5; wykorzystuje obiekt Data oraz parametr window.location.href by generowanie linki dla dowolnego odliczania.",
      notes: "w pełni responsywna",
      link: `${urlDict.repo_prefix}/classic/countdown/`,
      yearCreated: creationYears.countdown
    },
    hk: {
      title: "Heckler & Koch GmbH",
      image: tnHk,
      description: "Statyczna strona producenta broni zbudowana w opariu o HTML5, CSS oraz tagi @media. Javascript ograniczony do minimum.",
      notes: "w pełni responsywna",
      link: `${urlDict.repo_prefix}/classic/hk/`,
      yearCreated: creationYears.hk
    },
    nicola: {
      title: "NICOLA",
      image: tnNicola,
      description: "Strona promocyjna napojów Nicola przeznaczona jedynie na komputery stacjonarne. Element video osadzony jako tło centralnym elementem strony.",
      notes: "wersja na komputer stancjonarny",
      link: `${urlDict.repo_prefix}/classic/nicola/`,
      yearCreated: creationYears.nicola
    },
    petrochem: {
      title: "Petrochemical Associates International",
      image: tnPetrochem,
      description: "Statyczna strona korporacji z uniwersum Cyberpunk 2020; interaktywność strony osiągnięta poprzez użycie jQuery",
      notes: "w pełni responsywna",
      link: `${urlDict.repo_prefix}/classic/petrochem/`,
      yearCreated: creationYears.petrochem
    },
  },
  kr: { //please translate title, description and notes fields RE: ㅇㅋ 여보 <3
    orbitalAir: {
      title: "오비탈 에어",
      image: tnOrbitalAir,
      description: "HTML5, CSS 그리드 레이아웃을 활용한 오비탈 에어사의 웹사이트.",
      notes: "전체 반응형",
      link: `${urlDict.repo_prefix}/classic/orbital-air/`,
      yearCreated: creationYears.orbitalAir
    },
    obi: {
      title: "오비 - DIY 스토어",
      image: tnObi,
      description: "DIY 스토어인 오비 사이트를 반응형으로 리뉴얼한 프로젝트. Background-image 속성을 가진 타일을 배치하는 방식으로 디자인.",
      notes: "전체 반응형",
      link: `${urlDict.repo_prefix}/classic/obi/`,
      yearCreated: creationYears.obi
    },
    nightPizza: {
      title: "나이트시티 피자",
      image: tnNightPizza,
      description: "ES5/jQuery javascript를 이용한 JSON 데이터에 기반한 피자 웹사이트. 배경은 Canvas API를 활용, React 라이브러리에 대한 탐구 목적으로 진행된 프로젝트.",
      notes: "전체 반응형",
      link: `${urlDict.repo_prefix}/classic/night-pizza/`,
      yearCreated: creationYears.nightPizza
    },
    countdown: {
      title: "카운트다운",
      image: tnCountdown,
      description: "커스텀 URI 카운트다운 생성을 위한 window.location.href와, Date 객체를 포함한 vanilla ES5를 활용한 밀리터리 스타일의 카운트다운 웹사이트.",
      notes: "전체 반응형",
      link: `${urlDict.repo_prefix}/classic/countdown/`,
      yearCreated: creationYears.countdown
    },
    hk: {
      title: "헤클러 & 코흐 유한회사",
      image: tnHk,
      description: "반응형 구현을 위한 미디어쿼리와 HTML5, CSS3으로 제작된 무기 제조사 웹사이트.",
      notes: "전체 반응형",
      link: `${urlDict.repo_prefix}/classic/hk/`,
      yearCreated: creationYears.hk
    },
    nicola: {
      title: "니콜라",
      image: tnNicola,
      description: "주요 포커스로서 비디오 엘리먼트가 삽입된 니콜라 소다의 웹사이트.",
      notes: "PC 전용",
      link: `${urlDict.repo_prefix}/classic/nicola/`,
      yearCreated: creationYears.nicola
    },
    petrochem: {
      title: "국제석유화학협회",
      image: tnPetrochem,
      description: "상호작용을 위해 jQuery를 활용한 사이버펑크 2020 유니버스사의 정적 웹사이트.", //is this description right?
      notes: "전체 반응형",
      link: `${urlDict.repo_prefix}/classic/petrochem/`,
      yearCreated: creationYears.petrochem
    },
  },
}

export default classicLangData