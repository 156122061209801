import tnSTIMCoffee from "../assets/thumbnails/jpg/react-stim-coffee.jpg"
import tnWeatherWindow from "../assets/thumbnails/jpg/react-weather-window-cp2077.jpg"
import tnFindLyrics from "../assets/thumbnails/jpg/react-find-lyrics.jpg"
import tnTodoList from "../assets/thumbnails/jpg/react-todo-list.jpg"
import tnTbfPortfolio from "../assets/thumbnails/jpg/react-tbf.jpg"
import urlDict from "../config/urlDict"

let creationYears = {
  stimCoffee: 2020,
  weatherWindow: 2020,
  findLyrics: 2020,
  todoList: 2020,
  tbfPortfolio: 2020
}

let reactLangData ={
  en: {
    stimCoffee: {
      title: "STIMCoffee",
      image: tnSTIMCoffee,
      description: "Online coffee store, themed after Cyberpunk 2077 trailer ripperdoc UI, technologies used: React Hooks (16.8+), Font Awesome",
      notes: "fully responsive",
      link: `${urlDict.repo_prefix}/react/react-stim-coffee/`,
      yearCreated: creationYears.stimCoffee
    },
    weatherWindow: {
      title: "Weather Window Cyberpunk 2077",
      image: tnWeatherWindow,
      description: "Basic weather app; powered by React Hooks (useState, useEffect), data fetched from OpenWeather API",
      notes: "fully responsive",
      link: `${urlDict.repo_prefix}/react/react-weather-window-cp2077/`,
      yearCreated: creationYears.weatherWindow
    },
    findLyrics: {
      title: "Find Lyrics",
      image: tnFindLyrics,
      description: "Simple app for finding song lyrics; build using React class based components + React Boostrap",
      notes: "fully responsive",
      link: `${urlDict.repo_prefix}/react/react-find-lyrics/`,
      yearCreated: creationYears.findLyrics
    },
    todoList: {
      title: "Todo List",
      image: tnTodoList,
      description: "Todo list app made using on React Hooks",
      notes: "fully responsive",
      link: `${urlDict.repo_prefix}/react/react-todo-list/`,
      yearCreated: creationYears.todoList
    },
    tbfPortfolio: {
      title: "Portfolio",
      image: tnTbfPortfolio,
      description: "Portfolio website created using React Hooks",
      notes: "fully responsive",
      link: `${urlDict.portfolio_url}`,
      yearCreated: creationYears.tbfPortfolio
    }
  },
  pl: {
    stimCoffee: {
      title: "STIMCoffee",
      image: tnSTIMCoffee,
      description: "Sklep online stylizowany na interfejs użytkownika z trailera Cyberpunk 2077, użyte technologie: React Hooks (16.8+), Font Awesome",
      notes: "w pełni responsywna",
      link: `${urlDict.repo_prefix}/react/react-stim-coffee/`,
      yearCreated: creationYears.stimCoffee
    },
    weatherWindow: {
      title: "Weather Window Cyberpunk 2077",
      image: tnWeatherWindow,
      description: "Aplikacja podająca aktualną pogodę w wyszukiwanym mieście zbudowana na React Hooks (useState, useEffect), date pozyskiwane z OpenWeather API",
      notes: "w pełni responsywna",
      link: `${urlDict.repo_prefix}/react/react-weather-window-cp2077/`,
      yearCreated: creationYears.weatherWindow
    },
    findLyrics: {
      title: "Find Lyrics",
      image: tnFindLyrics,
      description: "Prosta aplikacja służąca wyszukiwania tekstów piosenek, zrealizowana w oparciu o komponenty React oparte na klasach oraz React Boostrap",
      notes: "w pełni responsywna",
      link: `${urlDict.repo_prefix}/react/react-find-lyrics/`,
      yearCreated: creationYears.findLyrics
    },
    todoList: {
      title: "Todo List",
      image: tnTodoList,
      description: "List rzeczy do zrobienia zrealizowana w oparciu o React Hooks (React 16.8+)",
      notes: "w pełni responsywna",
      link: `${urlDict.repo_prefix}/react/react-todo-list/`,
      yearCreated: creationYears.todoList
    },
    tbfPortfolio: {
      title: "Portfolio",
      image: tnTbfPortfolio,
      description: "Portfolio stworzone przy użyciu React Hooks",
      notes: "w pełni responsywna",
      link: `${urlDict.portfolio_url}`,
      yearCreated: creationYears.tbfPortfolio
    }
  },
  kr: { //please translate title, description and notes fields RE: ㅇㅋ 여보 <3
    stimCoffee: {
      title: "스팀커피",
      image: tnSTIMCoffee,
      description: "사이버펑크 2020 트레일러 ripperdoc UI의 테마를 차용한 온라인 커피 쇼핑몰 웹사이트. React Hooks(16.8+)과 Font Awesome을 활용.",
      notes: "전체 반응형",
      link: `${urlDict.repo_prefix}/react/react-stim-coffee/`,
      yearCreated: creationYears.stimCoffee
    },
    weatherWindow: {
      title: "날씨 윈도우 사이버펑크 2077",
      image: tnWeatherWindow,
      description: "기본적인 날씨 앱; React Hooks (useState, useEffect)를 활용, OpenWeather API를 통한 데이터 구동.",
      notes: "전체 반응형",
      link: `${urlDict.repo_prefix}/react/react-weather-window-cp2077/`,
      yearCreated: creationYears.weatherWindow
    },
    findLyrics: {
      title: "가사 찾기",
      image: tnFindLyrics,
      description: "노래 가사 검색을 위한 심플한 앱; 컴포넌트와 React Bootstrap에 기반한 React class를 활용해 제작.",
      notes: "전체 반응형",
      link: `${urlDict.repo_prefix}/react/react-find-lyrics/`,
      yearCreated: creationYears.findLyrics
    },
    todoList: {
      title: "Todo 리스트",
      image: tnTodoList,
      description: "React Hooks를 활용해 제작된 Todo 리스트 앱.",
      notes: "전체 반응형",
      link: `${urlDict.repo_prefix}/react/react-todo-list/`,
      yearCreated: creationYears.todoList
    },
    tbfPortfolio: {
      title: "포트폴리오",
      image: tnTbfPortfolio,
      description: "React Hooks를 이용해 제작된 포트폴리오 웹사이트.",
      notes: "전체 반응형",
      link: `${urlDict.portfolio_url}`,
      yearCreated: creationYears.tbfPortfolio
    }
  },
}

export default reactLangData