import React from 'react';
import './css/ContentItem.scss';
import SwitchLink from './SwitchLink';

import appLangData from '../data/appLangData'
import switchLang from '../utils/switchLang';
import {GeneralData} from '../App'

const ContentItem = (props) => {
  let lang = switchLang(GeneralData.getCurrentLanguage(), appLangData)

  return (
    <div className="ContentItem">
      <div className="Title">
        {props.data.title} <sup>{props.data.yearCreated}</sup>
      </div>
      <div className="Image">
        <img src={props.data.image} alt={props.data.title + " " + lang.contentItem.thumbnail}></img>
      </div>
      <div className="Description">
        {props.data.description}
      </div>
      <div className="Notes">
        {lang.contentItem.notes} {props.data.notes}
      </div>
      <div className="GoLive">
        <SwitchLink label={lang.contentItem.visit} link={props.data.link}/>
      </div>
    </div>
  );
}

export default ContentItem;
