import React from 'react';
// import { detectChrome } from '../utils/browserDetection';
import './css/AniBackground.scss';
// import AsciiAnimation from './ascii-animation/AsciiAnimation';
import MatrixRain from './MatrixRain';

const AniBackground = () => {

  return (
    <pre className="artContainer">
      <MatrixRain />
    </pre>
  )
}

export default AniBackground;
