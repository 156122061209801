import React from 'react';
import './css/Content.css';
import ContentItem from "./ContentItem"
import otherLangData from '../data/otherLangData'
import appLangData from '../data/appLangData'
import switchLang from '../utils/switchLang';
import {GeneralData} from '../App'

const ContentOther = () => {
  let data = switchLang(GeneralData.getCurrentLanguage(), otherLangData)
  let lang = switchLang(GeneralData.getCurrentLanguage(), appLangData)

  return (
    <div className="Content">
      <h2>{lang.contentOther.software}</h2>
      <ContentItem data={data.discoBitumen}/>
      <ContentItem data={data.flightWaw}/>
      <ContentItem data={data.honeycomb}/>
      <h2>{lang.contentOther.publications}</h2>
      <ContentItem data={data.paper1}/>
      <ContentItem data={data.paper2}/>
      <h2>{lang.contentOther.print3d}</h2>
      <ContentItem data={data.kangTao}/>
      <ContentItem data={data.incheonBridge}/>
    </div>
  );
}

export default ContentOther;
