import tnGeneric from "../assets/thumbnails/jpg/generic.jpg"
import tnDiscoBitumen from "../assets/thumbnails/jpg/disco-bitumen-adhesion.jpg"
import tnFlightWaw from "../assets/thumbnails/jpg/flight-WAW-ICN.jpg"
import tnHoneycombCrushing from "../assets/thumbnails/jpg/honeycomb-crushing.jpg"
import tnKangTao from "../assets/thumbnails/jpg/kang-tao.jpg"
import tnIncheonBridge from "../assets/thumbnails/jpg/incheon-bridge.jpg"
import urlDict from "../config/urlDict"


// etc imports (files for download)
import paper2Pdf from "../assets/etc/Brozyna_Kowalski-JBCh-2016_37-41.pdf"

let creationYears = {
  paper1: 2016,
  paper2: 2016,
  discoBitumen: 2016,
  flightWaw: 2017,
  honeycomb: 2014,
  kangTao: 2020,
  incheonBridge: 2018
}

let otherLangData ={
  en: {
    paper1: {
      title: "Assessment of PMB adhesion using computer image analysis",
      image: tnGeneric ,
      description: "This research paper presents findings on the topic of influence of polyethylene bitumen modification on the adhesion between bitumen and aggregate. Measurements were done using proprietary software employing computer vision",
      notes: "Archives of Civil Engineering",
      link: "https://www.researchgate.net/publication/313321283_Assessment_of_Polyethylen-Modified_Bitumen_Adhesion_Using_Computer_Image_Analysis",
      yearCreated: creationYears.paper1
    },
    paper2: {
      title: "Modification of asphalt binders with PE type polymers",
      image: tnGeneric ,
      description: "This investigation tested the effects of asphalt binder modification, using polyethylene-type polymers.",
      notes: "Journal of Building Chemistry",
      link: paper2Pdf,
      yearCreated: creationYears.paper2
    },
    discoBitumen: {
      title: "Script evaluating bitumen coverage",
      image: tnDiscoBitumen,
      description: "Wolfram Mathematica 10 script for bitumen-aggregate adhesion evaluation using computer vision.",
      notes: "Wolfram Mathematica 10",
      link: `${urlDict.github_prefix}/disco-bitumen-adhesion`,
      yearCreated: creationYears.discoBitumen
    },
    flightWaw: {
      title: "Long distance flight pricing exploration",
      image: tnFlightWaw,
      description: "Analysis of plane ticket pricing on the Warsaw (Poland) - Seul (South Korea) air connection, based on how far ahead in time the ticket gets booked. Data (7000 entries) set scrapped from web using a python script, analysis conducted in Jupyter Notebooks.",
      notes: "Python 3 (pandas, numpy, pyplot)",
      link: `${urlDict.github_prefix}/flight-WAW-ICN`,
      yearCreated: creationYears.flightWaw
    },
    honeycomb: {
      title: "Mean and ultimate crushing force of honeycombs",
      image: tnHoneycombCrushing,
      description: "Comparison of analytical results for a honeycomb type structure under crushing load. Finite element model created in HyperMesh, calculations done in LS-DYNA, data analysis using Jupyter Notebooks.",
      notes: "LS-DYNA, Python 3",
      link: `${urlDict.github_prefix}/honeycomb-crushing`,
      yearCreated: creationYears.honeycomb
    },
    kangTao: {
      title: "Kang Tao - Cyberpunk 2077",
      image: tnKangTao,
      description: "3d model of a kinematically accurate SMG from the Cyberpunk 2077 trailer. Released to the public domain.",
      notes: "Autodesk Fusion360, Simplify3d",
      link: "https://www.thingiverse.com/thing:3879735",
      yearCreated: creationYears.kangTao
    },
    incheonBridge: {
      title: "Incheon Bridge",
      image: tnIncheonBridge,
      description: "3d model of Incheon bridge, with flexible deck anchored using thread to middle pylon.",
      notes: "Autodesk Fusion360, Simplify3d, Anycubic Kossel",
      link: "https://www.thingiverse.com/thing:3779578",
      yearCreated: creationYears.incheonBridge
    },
  },
  pl: {
    paper1: {
      title: "Assessment of PMB adhesion using computer image analysis",
      image: tnGeneric ,
      description: "Publikacja opisuje wpływ dodatku polietylenów do lepiszczy asfaltowych na adhezję do kruszywa. Badanie przeprowadzono z użyciem autorskiego oprogramowania mierzącego pokrycie kruszywa lepiszczem w oparciu o wizję komputerową",
      notes: "Archives of Civil Engineering",
      link: "https://www.researchgate.net/publication/313321283_Assessment_of_Polyethylen-Modified_Bitumen_Adhesion_Using_Computer_Image_Analysis",
      yearCreated: creationYears.paper1
    },
    paper2: {
      title: "Modyfikacja lepiszczy asfaltowych przy użyciu polietylenów",
      image: tnGeneric ,
      description: "W ramach przeprowadzonych badań zbadano wpływ dodatku polietylenów na właściwości fizyczne lepiszczy asfaltowych",
      notes: "Journal of Building Chemistry",
      link: paper2Pdf,
      yearCreated: creationYears.paper2
    },
    discoBitumen: {
      title: "Skrypt kwantyfikujący otoczenie kruszywa lepiszcznem",
      image: tnDiscoBitumen,
      description: "Skrypt napisany w Wolfram Mathematica 10 służący do oceny otoczenia kruszywa lepiszcznem na podstawie analizy zdjęć cyfrowych.",
      notes: "Wolfram Mathematica 10",
      link: `${urlDict.github_prefix}/disco-bitumen-adhesion`,
      yearCreated: creationYears.discoBitumen
    },
    flightWaw: {
      title: "Ceny międzynarodowych biletów lotnicznych",
      image: tnFlightWaw,
      description: "Analiza cen biletów lotnicznych dla połączenia Warszawa (Polska) - Seul (Korea Południowa) w kontekcie wyprzedzenia przy zakupie biletów. Zbiór danych (7000 rekordów) pozyskany za pomocą skryptu napisanego w pythonie, analiza przy użyciu Jupyter Notebooks.",
      notes: "Python 3 (Pandas, numpy, pyplot)",
      link: `${urlDict.github_prefix}/flight-WAW-ICN`,
      yearCreated: creationYears.flightWaw
    },
    honeycomb: {
      title: "Konstrukcje o strukturze plastra miodu w ściskaniu",
      image: tnHoneycombCrushing,
      description: "Porównanie wartości ściskania dla konstrukcji o strukturze plastra miodu uzyskanych metodami analitycznymi oraz MES. Model wykonany w HyperMesh, obliczenia LS-DYNA, analiza wyników Jupyter Notebooks.",
      notes: "LS-DYNA, Python 3",
      link: `${urlDict.github_prefix}/honeycomb-crushing`,
      yearCreated: creationYears.honeycomb
    },
    kangTao: {
      title: "Kang Tao - Cyberpunk 2077",
      image: tnKangTao,
      description: "Kinematycznie poprawny model 3d pistoletu maszynowego ze zwiastunu gry Cyberpunk 2077. Wypuszczony do domeny publicznej",
      notes: "Autodesk Fusion360, Simplify3d",
      link: "https://www.thingiverse.com/thing:3879735",
      yearCreated: creationYears.kangTao
    },
    incheonBridge: {
      title: "Most Incheon",
      image: tnIncheonBridge,
      description: "Model 3d mostu Incheon wraz z elastyczną płytą pomostu podwieszoną za pomocą nici do głównego pylonu.",
      notes: "Autodesk Fusion360, Simplify3d, Anycubic Kossel",
      link: "https://www.thingiverse.com/thing:3779578",
      yearCreated: creationYears.incheonBridge
    },
  },
  kr: { //please translate the title and description fields RE: ㅇㅋ 여보 <3
    paper1: {
      title: "컴퓨터 이미지 분석을 이용한 PMB 접착력 평가",
      image: tnGeneric ,
      description: "폴리에틸렌 아스팔트 개조가 아스팔트와 골재의 접착력에 미치는 영향에 관한 연구결과를 제시하는 논문.",
      notes: "Archives of Civil Engineering",
      link: "https://www.researchgate.net/publication/313321283_Assessment_of_Polyethylen-Modified_Bitumen_Adhesion_Using_Computer_Image_Analysis",
      yearCreated: creationYears.paper1
    },
    paper2: {
      title: "PE형 중합체를 이용한 아스팔트 바인더 수정",
      image: tnGeneric ,
      description: "폴리에틸렌형 중합체를 이용한 아스팔트 바인더 수정의 효과에 대한 실험.",
      notes: "Journal of Building Chemistry",
      link: paper2Pdf,
      yearCreated: creationYears.paper2
    },
    discoBitumen: {
      title: "아스팔트 커버리지 평가 스크립트",
      image: tnDiscoBitumen,
      description: "Computer vision을 활용한 아스팔트-골재 간 접착력 평가 스크립트.",
      notes: "Wolfram Mathematica 10",
      link: `${urlDict.github_prefix}/disco-bitumen-adhesion`,
      yearCreated: creationYears.discoBitumen
    },
    flightWaw: {
      title: "장거리 비행 가격 탐구",
      image: tnFlightWaw,
      description: "표가 얼마나 먼저 예약되었는지에 기반한 바르샤바(폴란드)-서울(대한민국) 간 비행기 표 가격 분석 프로젝트. Python script를 통해 웹으로부터 7000개의 데이터 셋 획득, Jupyter Notebooks에서 분석 수행.",
      notes: "Python 3 (pandas, numpy, pyplot)",
      link: `${urlDict.github_prefix}/flight-WAW-ICN`,
      yearCreated: creationYears.flightWaw
    },
    honeycomb: {
      title: "벌집형 구조물의 극강의 파괴력",
      image: tnHoneycombCrushing,
      description: "압착하중을 받는 벌집형 구조물에 대한 해석 결과 비교 프로젝트. HyperMesh에서 유한한 엘리먼트 모델 제작, LS-DYNA에서 수행된 산출, Jupyter Notebooks를 통한 데이터 분석.",
      notes: "LS-DYNA, Python 3",
      link: `${urlDict.github_prefix}/honeycomb-crushing`,
      yearCreated: creationYears.honeycomb
    },
    kangTao: {
      title: "캉 타오 - 사이버펑크 2077",
      image: tnKangTao,
      description: "사이버펑크 2020 트레일러의 3d 모델. 퍼블릭 도메인에 공개됨.",
      notes: "Autodesk Fusion360, Simplify3d",
      link: "https://www.thingiverse.com/thing:3879735",
      yearCreated: creationYears.kangTao
    },
    incheonBridge: {
      title: "인천대교",
      image: tnIncheonBridge,
      description: "중간 철탑에 연결된, 가느다란 구조물로 고정된 유연한 데크가 돋보이는 인천대교의 3d 모델.",
      notes: "Autodesk Fusion360, Simplify3d, Anycubic Kossel",
      link: "https://www.thingiverse.com/thing:3779578",
      yearCreated: creationYears.incheonBridge
    },
  },
}

export default otherLangData