import React from 'react';
import './css/MenuIcon.scss';

import appLangData from '../data/appLangData'
import switchLang from '../utils/switchLang';
import {GeneralData} from '../App'

const MenuIcon = (props) => {
  let lang = switchLang(GeneralData.getCurrentLanguage(), appLangData)
  return (
    <div 
      className="MenuIcon"
      onClick={()=>props.cbToggleMenu()}
    >{lang.menuIcon.label}</div>
  );
}

export default MenuIcon;
