import React from 'react';
import './css/TopBar.scss';

import appLangData from '../data/appLangData'
import switchLang from '../utils/switchLang';
import { GeneralData } from '../App'

const TopBar = (props) => {
  let lang = switchLang(GeneralData.getCurrentLanguage(), appLangData)

  // const prefix = "trailblazingfive.net"
  const prefix = ""

  return (
    <div className="TopBar">
      <div>
        <h1>{prefix}/{lang.topBar[GeneralData.getCurrentContent()]}</h1>
      </div>
      <div className="Languages">
        <span
          className={(props.language === 'en') ? "selected" : "notSelected"}
          onClick={() => props.cbToggleLanguage('en')}
        >EN</span>|
        <span
          className={(props.language === 'pl') ? "selected" : "notSelected"}
          onClick={() => props.cbToggleLanguage('pl')}
        >PL</span>|
        <span
          className={(props.language === 'kr') ? "selected" : "notSelected"}
          onClick={() => props.cbToggleLanguage('kr')}
        >KR</span>
      </div>
    </div>
  );
}

export default TopBar;
