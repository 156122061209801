import React from 'react';
import './css/AniTranslation.scss';

import appLangData from '../data/appLangData'
import switchLang from '../utils/switchLang';
import {GeneralData} from '../App'


const AniTranslation = () => {
  let lang = switchLang(GeneralData.getCurrentLanguage(), appLangData)

  return (
    <div className="AniTranslation">
      <span className="Notification">
        {lang.aniTranslation.translating}<br/>...
      </span>
    </div>
  );
}

export default AniTranslation;
