import React from 'react';
import './css/Switch.scss';

import {GeneralData} from '../App'

const Switch = (props) => {

  return (
    <div className={(props.content === GeneralData.currentContent) ? "SwitchSelected" : "Switch"}
      onClick={() => props.cbChangeContent(props.content)}
    >
      {props.label}
    </div >
  );
}

export default Switch;
