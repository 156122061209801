import React from 'react';
import './css/Content.css';
import ContentItem from "./ContentItem"
import classicLangData from '../data/classicLangData'
import switchLang from '../utils/switchLang';
import {GeneralData} from '../App'


const ContentClassic = (props) => {
  let data = switchLang(GeneralData.getCurrentLanguage(), classicLangData)

  return (
    <div className="Content">
      {Object.keys(data).map(key => (
        <ContentItem data={data[key]}/>
      ))}
    </div>
  );
}

export default ContentClassic;
