import React from 'react';
import './css/Content.css';

// import ContentItem from "./ContentItem"


// import bioLangData from '../data/bioLangData'
// import appLangData from '../data/appLangData'
// import switchLang from '../utils/switchLang';
// import { GeneralData } from '../App'

const ContentBio = () => {
  // let data = switchLang(GeneralData.getCurrentLanguage(), bioLangData)
  // let lang = switchLang(GeneralData.getCurrentLanguage(), appLangData)

  return (
    <div className="Content">
      <div className="IframeContainer">
      <iframe id="IframeTerminal" src="https://portfolio.trailblazingfive.net/repo/aux/about-me-terminal" scrolling="no" title="about-me-terminal"></iframe>

      </div>
    </div>
  );
}

export default ContentBio;
