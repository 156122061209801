let appLangData ={
  en: {
    menu: {
      classic: "Static websites",
      react: "React",
      other: "Other",
      bio: "About me"
    },
    menuIcon: {
      label: "Menu"
    },
    contentOther: {
      software: "Software",
      publications: "Publications",
      print3d: "3d printing" 
    },
    contentBio: {
      general: "General information",
      tech: "Technologies"
    },
    contentItem: {
      notes: "Notes:",
      visit: "Visit",
      thumbnail: "thumbnail"
    },
    topBar: {
      classic: "static_websites",
      react: "react",
      other: "other",
      bio: "about_me"
    },
    aniTranslation: {
      translating: "translating"
    },
    credit: {
      copyright: "Copyright"
    },
    tableItem: {
      clearTip: "Double-click to display"
    }
  },
  pl: {
    menu: {
      classic: "Strony statyczne",
      react: "React",
      other: "Inne",
      bio: "O mnie"
    },
    menuIcon: {
      label: "Menu"
    },
    contentOther: {
      software: "Programy",
      publications: "Publikacje",
      print3d: "Druk 3d" 
    },
    contentBio: {
      general: "Informacje ogólne",
      tech: "Technologie"
    },
    contentItem: {
      notes: "Uwagi:",
      visit: "Otwórz",
      thumbnail: "miniatura"
    },
    topBar: {
      classic: "strony_statyczne",
      react: "react",
      other: "inne",
      bio: "o_mnie"
    },
    aniTranslation: {
      translating: "tłumaczę"
    },
    credit: {
      copyright: "Prawa autorskie"
    },
    tableItem: {
      clearTip: "Kliknij podwójnie by wyświetlić"
    }
  },
  kr: { // please translate all RE: ㅇㅋ 여보 <3
    menu: {
      classic: "정적 웹사이트",
      react: "React", // I think React would be proper for this, because people use it as English - but you can also use 리액트 instead.
      other: "기타",
      bio: "내 소개"
    },
    menuIcon: {
      label: "메뉴"
    },
    contentOther: {
      software: "소프트웨어",
      publications: "출판물",
      print3d: "3d 프린팅" 
    },
    contentBio: {
      general: "일반 정보",
      tech: "기술"
    },
    contentItem: {
      notes: "참고:",
      visit: "열기",
      thumbnail: "썸네일 이미지"
    },
    topBar: {
      classic: "정적_웹사이트",
      react: "리액트",
      other: "기타",
      bio: "내_소개"
    },
    aniTranslation: {
      translating: "번역 중"
    },
    credit: {
      copyright: "저작권"
    },
    tableItem: {
      clearTip: "더블클릭하여 표시"
    }
  },
}

export default appLangData