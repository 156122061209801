import React from 'react';
import './css/Switch.scss';

const SwitchLink = (props) => {


  return (
    <a
      href={props.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={(!props.selected) ? "Switch" : "SwitchSelected"}
      >
        {props.label}
      </div>
    </a>
  );
}

export default SwitchLink;
