import React, { useState, useEffect } from 'react';
import './App.css';

import TopBar from './components/TopBar'
import MainMenu from './components/MainMenu'
import Content from './components/Content'
import MenuIcon from './components/MenuIcon'
import Credit from './components/Credit'
import AniTranslation from './components/AniTranslation';
import AniBackground from './components/AniBackground'


import { GlobalData, MOBILE_THRESHOLD } from './utils/globalData'
let GeneralData = new GlobalData();
const animationDuration = 300

const App = () => {

  const [language, setLanguage] = useState(GeneralData.getCurrentLanguage())

  const [animate, setAnimate] = useState()


  const cbToggleLanguage = (language) => {
    setAnimate(<AniTranslation/>)
    setTimeout(()=>{setAnimate()}, animationDuration)
    setLanguage(language)
    GeneralData.setCurrentLanguage(language)
  }


  const [content, setContent] = useState(GeneralData.getCurrentContent())

  const cbChangeContent = (content) => {
    setContent(content)
    GeneralData.setCurrentContent(content)
  }

  const [toggleMenu, setToggleMenu] = useState(GeneralData.getToggleMenu())
  // const [toggleMenu, setToggleMenu] = useState(true)


  const handleResize = () => {
    if (window.innerWidth >= MOBILE_THRESHOLD) {
      setToggleMenu(true)
    } else {
      setToggleMenu(false)
    }
  }

  useEffect(()=> {
    handleResize()
  window.addEventListener('resize',handleResize)
  },[])


  const cbToggleMenu = () => {
    setToggleMenu(!toggleMenu)
    GeneralData.setToggleMenu(toggleMenu)
  }

  // const showMenu = () => {
  //   console.log('showMenu called')
  //   if (window.innerWidth >= MOBILE_THRESHOLD) {
  //     setToggleMenu(true)
  //     GeneralData.setToggleMenu(true)
  //   }
  // }

  // const hideMenu = () => {
  //   console.log('hideMenu called')
  //   if (window.innerWidth <= MOBILE_THRESHOLD) {
  //     setToggleMenu(false)
  //     GeneralData.setToggleMenu(false)
  //   }
  // }

  // window.addEventListener('resize',showMenu, false);

  // window.addEventListener('resize', CheckBrowserSize, false);
  // function CheckBrowserSize() {
  //   var ResX = document.body.offsetHeight;
  //   var ResY = document.body.offsetWidth;
  //   console.log(ResX, ResY)
  // }


  return (
    <div className="App">
      <TopBar
        language={language}
        cbToggleLanguage={cbToggleLanguage} />
      <MainMenu
        toggleMenu={toggleMenu}
        cbChangeContent={cbChangeContent} />
      <Content
        content={content} />
      <MenuIcon cbToggleMenu={cbToggleMenu} />
      <Credit />
      {animate}
      <AniBackground/>
    </div>
  );
}

export { App, GeneralData };