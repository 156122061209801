import React, { useEffect } from "react";
import runAnimation from "../utils/matrixRainAnimation";

const MatrixRain = (props) => {
    useEffect(() => {
        runAnimation();
    }, []);

    return <canvas id="canvasBackgroundAnimation"></canvas>;
};

export default MatrixRain;
